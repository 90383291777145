import React from 'react'
import { graphql } from 'gatsby'

import DefaultLayout from '@hoc/Layout/Default'
import BladeResolver from '@hoc/BladeResolver'
import Meta from '@hoc/Meta'

const Page = ({ data, pageContext }) => {
    const { theme, allPosts } = pageContext
    const { careers, page } = data

    return (
        <DefaultLayout {...{ theme }}>
            <Meta {...{ seo: page?.seo }} />
            <BladeResolver
                {...{
                    blades: page?.blades || [],
                    careers: careers?.edges || [],
                    allPosts: allPosts?.map(({ node }) => node) || [],
                    theme,
                }}
            />
        </DefaultLayout>
    )
}

export const query = graphql`
    query getPage($id: String) {
        # Comment out the careers query
        #
        # careers: allWorkableJob(limit: 100) {
        #    edges {
        #       node {
        #            id
        #            state
        #            application_url
        #            url
        #            department
        #            description
        #            employment_type
        #            shortcode
        #            title
        #            created_at
        #        }
        #    }
        #}
        #
        page: datoCmsPage(id: { eq: $id }) {
            id
            slug
            seo {
                description
                title
                twitterCard
                image {
                    url
                }
            }
            blades {
                id: bladeId
                color
                backgroundImage {
                    gatsbyImageData
                }
                blocks {
                    ... on DatoCmsHero {
                        ...Hero
                    }
                    ... on DatoCmsHeroSimple {
                        ...HeroSimple
                    }
                    ... on DatoCmsHeroThumbnail {
                        ...HeroThumbnail
                    }
                    ... on DatoCmsHeroNews {
                        ...HeroNews
                    }
                    ... on DatoCmsThumbnail {
                        ...Thumbnail
                    }
                    ... on DatoCmsVideoThumbnail {
                        ...VideoThumbnail
                    }
                    ... on DatoCmsIconCard {
                        ...IconCards
                    }
                    ... on DatoCmsCareer {
                        ...Careers
                    }
                    ... on DatoCmsCareerFull {
                        ...CareersFull
                    }
                    ... on DatoCmsLatestNews {
                        ...LatestNews
                    }
                    ... on DatoCmsSocial {
                        ...Social
                    }
                    ... on DatoCmsNewsGrid {
                        ...NewsGrid
                    }
                    ... on DatoCmsEditor {
                        ...Editor
                    }
                }
            }
        }
    }
`

export default Page
